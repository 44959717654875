import React, { useContext, useState, useEffect } from 'react';
import { StaticImage } from "gatsby-plugin-image"

import axios from 'axios';
import Seo from "../components/Seo"
import Hero from '../components/Hero'

const countryList = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas (the)",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory (the)",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands (the)",
	"Central African Republic (the)",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands (the)",
	"Colombia",
	"Comoros (the)",
	"Congo (the Democratic Republic of the)",
	"Congo (the)",
	"Cook Islands (the)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic (the)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (the) [Malvinas]",
	"Faroe Islands (the)",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories (the)",
	"Gabon",
	"Gambia (the)",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (the)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic (the)",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands (the)",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands (the)",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger (the)",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands (the)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines (the)",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation (the)",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan (the)",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands (the)",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates (the)",
	"United Kingdom of Great Britain and Northern Ireland (the)",
	"United States Minor Outlying Islands (the)",
	"United States of America (the)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];


export default function FormTest({ data }) {
  const [ formData, setFormData ] = useState({});
  const [postingData, setPostingData] = useState(false);
  // = = = = = = = = 
  // Events Functions

  // Add data to States
  const handleChange = e => {

    // Set Form Data
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    
    // Add data from selectedCompany if these fields are undefined
    if(formData.company === undefined) {
      setFormData({
        ...formData,
        company: selectedCompany.name,
        [e.target.name]: e.target.value
      });
    }
    if(formData.address === undefined) {
      setFormData({
        ...formData,
        address: selectedCompany.addressShort,
        [e.target.name]: e.target.value
      });
    }
    if(formData.postcode === undefined) {
      setFormData({
        ...formData,
        postcode: selectedCompany.postCode,
        [e.target.name]: e.target.value
      });
    }
    // Check this data by default
    if(formData.workingSince === undefined) {
      setFormData({
        ...formData,
        workingSince: 'I have worked continuously for this organisation in the City since September last year.',
        [e.target.name]: e.target.value
      });
    }
    // Add consent if user didn't interact with it's radio buttons
    if(formData.consent === undefined) {
      setFormData({
        ...formData,
        consent: 'yes',
        [e.target.name]: e.target.value
      });
    }
  }

  // Submits Form Data
  const handleSubmit = e => {
    e.preventDefault();
    let myForm = e.target;

    // Show spinner
    setPostingData(true);
    // Send to Netlify Forms
    fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        "form-name": myForm.getAttribute('name'),
        ...formData
      }).toString()
    })
    .then(response => {
      // Hide spinner
      setPostingData(false);
    })
    .catch((error) => {
      setPostingData(false);
      alert(error);
    })
  }

  const handleCheckboxChange = e => {

  }

  const selectedCompany = {
    name: 'TEST',
    postCode: '00000',
    addressShort: 'testAddress'
  }

  return(
    <>
      <Seo title = "Form TEST" />

      <Hero 
        headline = "Form TEST"
      >
        <StaticImage 
          className="hero__picture"
          src="../../static/media/about-hero.jpg" 
          alt="" 
          loading="eager"
        />
      </Hero>

      <div className="page-content">
        <form
          onSubmit={ (e) => handleSubmit(e) } 
          className="form form--interest"  
          name="Company Email Form" 
          method="POST" 
          netlify-honeypot="bot-field"
          data-netlify="true"
          id="company-email-form"
        >
          <input readOnly type="hidden" name="form-name" value="Company Email Form" />

          <div className="field-group">
            <div className="input-wrapper input-wrapper--company">
              <input 
                  type="text" 
                  className="input-block"
                  value={selectedCompany.name} 
                  name="company"
                  id="company" 
                  placeholder={selectedCompany.name}
                  required
                  readOnly
                />
              <span className={`input__custom-label ${ selectedCompany.name ? 'show' : '' }`}>Organisation Name</span>
            </div>
          </div>

          <div className="field-group">
            <div className="input-wrapper">
              <input 
                onChange={(e) => handleChange(e)} 
                type="text" 
                value={formData.firstName ? formData.firstName : ""} 
                name="firstName" 
                id="first-name" 
                placeholder="First Name*"
                required
              />
              <span className={`input__custom-label ${ formData.firstName ? 'show' : '' }`}>Name</span>
            </div>
            
            <div className="input-wrapper">
              <input 
                onChange={(e) => handleChange(e)} 
                type="text" 
                value={formData.lastName ? formData.lastName : ""} 
                name="lastName" 
                id="last-name" 
                placeholder="Last Name*"
                required
              />
              <span className={`input__custom-label ${ formData.lastName ? 'show' : '' }`}>Lastname</span>
            </div>
          </div>

          <div className="field-group">
            <div className="input-wrapper">
              <input 
                onChange={(e) => handleChange(e)} 
                type="email" 
                value={formData.email ? formData.email : ""} 
                name="email" 
                id="email" 
                placeholder="Work Email*"
                required
              />
              <span className={`input__custom-label ${ formData.email ? 'show' : '' }`}>Work Email</span>
            </div>

            <div className="input-wrapper">
              <input 
                onChange={(e) => handleChange(e)} 
                type="tel" 
                value={formData.phone ? formData.phone : ""} 
                name="phone" 
                id="phone" 
                placeholder="Mobile Phone"
              />
              <span className={`input__custom-label ${ formData.phone ? 'show' : '' }`}>Mobile Phone</span>
            </div>
          </div>

          <div className="field-group">
            <div className="input-wrapper">
              <input
                type="text" 
                className="input-block"
                value={selectedCompany.addressShort} 
                name="address" 
                id="address" 
                placeholder={selectedCompany.addressShort}
                required
                readOnly
              />
              <span className={`input__custom-label ${ selectedCompany.addressShort ? 'show' : '' }`}>Work Address</span>
            </div>

            <div className="input-wrapper input-wrapper--postcode">
              <input
                type="text" 
                className="input-block"
                value={selectedCompany.postCode} 
                name="postcode" 
                id="postcode" 
                placeholder={`${selectedCompany.postCode}`}
                required
                readOnly
              />
              <span className={`input__custom-label ${ selectedCompany.postCode ? 'show' : '' }`}>Postcode</span>
            </div>
          </div>

          <div className="field-group">
        <fieldset className="fieldset-wrapper">
          <legend>Tick any that apply:</legend>

          <div className="radio-wrapper">
            <input 
              onChange={ (e) => handleChange(e) } 
              type="radio" 
              id="sept-last-year" 
              value="I have worked continuously for this organisation in the City since September last year." 
              name="workingSince"
              checked={ (formData.workingSince === 'I have worked continuously for this organisation in the City since September last year.' || formData.workingSince === undefined) ? true : false} 
            />
            <label className="radio-white" htmlFor="sept-last-year">I have worked continuously for this organisation in the City since September last year.</label><br/>
          </div>
          <div className="radio-wrapper">
            <input 
              onChange={ (e) => handleChange(e) } 
              type="radio" 
              id="five-years" 
              value="I have worked for this organisation in the City for a combined period of five years - with some of this taking place in the past five years." 
              name="workingSince"
              checked={ formData.workingSince === 'I have worked for this organisation in the City for a combined period of five years - with some of this taking place in the past five years.' ? true : false} 
            />
            <label className="radio-white" htmlFor="five-years">I have worked for this organisation in the City for a combined period of five years - with some of this taking place in the past five years.</label><br/>
          </div>
          <div className="radio-wrapper">
            <input 
              onChange={ (e) => handleChange(e) } 
              type="radio" 
              id="ten-years" 
              value="I have worked in the City for any organisation - for a combined period of ten years or more." 
              name="workingSince"
              checked={ formData.workingSince === 'I have worked in the City for any organisation - for a combined period of ten years or more.' ? true : false} 
            />
            <label className="radio-white" htmlFor="ten-years">I have worked in the City for any organisation - for a combined period of ten years or more.</label><br/>
          </div>
          <div className="radio-wrapper">
            <input 
              onChange={ (e) => handleChange(e) } 
              type="radio" 
              id="first-sept-last-year" 
              value="I have been a member of this organisation’s Board of Directors or Governing Body since 1st September last year." 
              name="workingSince"
              checked={ formData.workingSince === 'I have been a member of this organisation’s Board of Directors or Governing Body since 1st September last year.' ? true : false} 
            />
            <label className="radio-white" htmlFor="first-sept-last-year">I have been a member of this organisation’s Board of Directors or Governing Body since 1st September last year.</label><br/>
          </div>
        </fieldset>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <label htmlFor="select-standing" className="select__label">Nationality:</label>
          <div className="select__wrapper">
            
            <select  onChange={ (e) => handleChange(e) } className="select__input" name="nationality" id="select-nationality">
              <option className="select__option" value="">Select</option>
              {
                countryList.map(country => <option key={country} className="select__option" value={country}>{country}</option>)
              }
            </select>
            
            <div className="select__icon">

            </div>

          </div>
        </div>
      </div>

      <p>If we have a voter registration contact at your organisation, we will send them this information. In the event we do not, could you suggest an appropriate person at your organisation, who we could contact about this? (optional).</p>

      <div className="field-group">
        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.contactName ? formData.contactName : ""} 
            name="contactName"
            id="contact-job-title" 
            placeholder="Name"
            required
          />
          <span className={`input__custom-label ${ formData.contactName ? 'show' : '' }`}>Name</span>
        </div>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.contactEmail ? formData.contactEmail : ""} 
            name="contactEmail"
            id="contact-email" 
            placeholder="Email"
            required
          />
          <span className={`input__custom-label ${ formData.contactEmail ? 'show' : '' }`}>Email</span>
        </div>
      </div>

      <div className="field-group">
        <div className="input-wrapper">
          <input 
            onChange={(e) => handleChange(e)} 
            type="text" 
            value={formData.contactJobTitle ? formData.contactJobTitle : ""} 
            name="contactJobTitle"
            id="contact-job-title" 
            placeholder="Job title"
            required
          />
          <span className={`input__custom-label ${ formData.contactJobTitle ? 'show' : '' }`}>Job title</span>
        </div>
      </div>

      <div className="consent__inputs mb-2">
        <div className="checkbox-wrapper">
          <input 
            className='checkmark' 
            onChange={ (e) => handleCheckboxChange(e) } 
            id="chkconsent1" 
            name="chkconsent1" 
            type="checkbox"             
          />
          <label htmlFor="chkconsent1">Communications related to the Speak for the City election engagement campaign, including information on my registration status</label>
        </div>
        <div className="checkbox-wrapper">
          <input 
            className="checkmark" 
            onChange={ (e) => handleCheckboxChange(e) } 
            id="chkconsent2" 
            name="chkconsent2" 
            type="checkbox"              
          />
          <label htmlFor="chkconsent2">Community engagement updates, such as information about upcoming events and consultations</label>  
        </div>
      </div>

      <div className="form__consent">
        <div className="consent__text">
          <p>I am a member of staff at this organisation and I consent for the City of London Corporation to share the information I have provided with their contact at this organisation for the purposes of electoral registration. The City of London Corporation will only use the information you give us for electoral purposes. Please see our privacy statement for more information.</p>
        </div>
        <div className="consent__inputs">

          <div className="radio-wrapper">
            <input 
              onChange={ (e) => handleChange(e) } 
              id="yes" 
              name="consent" 
              type="radio" 
              value="yes" 
              checked={ (formData.consent === 'yes' || formData.consent === undefined) ? true : false} 
            />
            <label htmlFor="yes">Yes</label>
          </div>

          <div className="radio-wrapper">
            <input 
              onChange={ (e) => handleChange(e) } 
              id="no" 
              name="consent" 
              type="radio" 
              value="no" 
              checked={ formData.consent === 'no' ? true : false} 
            />
            <label htmlFor="no">No</label>
          </div>

        </div>  
      </div>


      <div className="button-group">
        <div className="button button--submit">
          {
            postingData !== true
              ? <input readOnly type="submit" value="Submit" name="submit" id="company-submit" />
              
              : <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
          }
        </div>
      </div>
        </form>
      </div>
    </>
  )
}